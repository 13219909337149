import { useNavigationItems } from '../utils/hooks'

export default function Navigation() {
  const nav = useNavigationItems()

  return (
    <div className="hidden md:flex">
      <div data-cy="navigation-pane" className="align-end flex flex-row items-center">
        {nav.map((item, index) => (
          <a
            href={item.to}
            data-cy={item.key}
            key={index}
            className={`
          text-center rounded-xl py-2 px-4 font-medium
          hover:bg-slate-200 mx-2 text-[rgba(25,25,27,0.75)] 
          hover:text-[var(--theme-primary)] bg-slate-100
            
          `}
            rel="prefetch">
            <span>{item.label}</span>
          </a>
        ))}
      </div>
    </div>
  )
}
