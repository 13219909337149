import WithApp from '../../_utils/withApp.tsx'
import { Context } from '../../_types'
import Navigation from './navigation'

const Component = () => <Navigation />

export default function SharedMenu({ context }: { context: Context }) {
  return (
    <WithApp context={context}>
      <Component />
    </WithApp>
  )
}
